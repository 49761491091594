<template>
  <div>
    <loading v-if="isLoading" />
    <error v-else-if="isError" />
    <content-not-view v-else-if="!subPermission.index" />

    <b-card v-else>
      <div class="custom-search d-flex justify-content-between mb-1">
        <!-- search input -->
        <b-form-group>
          <div class="d-flex align-items-center">
            <div>
              <label class="mr-1">{{ $t("g.searchLabel") }}</label>
              <b-form-input
                v-model="searchTerm"
                :placeholder="$t('g.searchHere')"
                type="text"
                class="d-inline-block"
              />
            </div>
          </div>
        </b-form-group>
        <div class="d-flex align-items-center" v-if="subPermission.store">
          <b-button :to="{ name: 'AddSuppliers' }" variant="primary">{{
            $t("g.AddSupplier")
          }}</b-button>
        </div>
      </div>

      <!-- table -->
      <vue-good-table
        :columns="columns"
        :rows="rows"
        :rtl="direction"
        styleClass="vgt-table condensed"
        :search-options="{
          enabled: true,
          externalQuery: searchTerm,
        }"
        :select-options="{
          enabled: true,
          selectOnCheckboxOnly: true, // only select when checkbox is clicked instead of the row
          selectionInfoClass: 'custom-class',
          selectionText: 'rows selected',
          clearSelectionText: 'clear',
          disableSelectInfo: true, // disable the select info panel on top
          selectAllByGroup: true, // when used in combination with a grouped table, add a checkbox in the header row to check/uncheck the entire group
        }"
        :pagination-options="{
          enabled: true,
          perPage: pageLength,
        }"
      >
        <template slot="table-column" slot-scope="props">
          <!-- Column: Name -->
          <span v-if="props.column.label === 'name'" class="text-nowrap">
            {{ $t("g.name") }}
          </span>
          <span
            v-else-if="props.column.label === 'is_available'"
            class="text-nowrap"
          >
            {{ $t("g.Available") }}
          </span>
          <span v-else-if="props.column.label === 'action'" class="text-nowrap">
            {{ $t("g.action") }}
          </span>
          <span v-else>
            {{ props.column.label }}
          </span>
        </template>

        <!-- Slot: Table Row -->
        >

        <template slot="table-row" slot-scope="props">
          
          <!-- Column: Action -->
          <span v-if="props.column.field === 'name'">
            <router-link
              v-if="subPermission.show"
              :to="{ name: 'ViewSupplier', params: { id: props.row.id } }"
            >
              {{ props.row.name }}
            </router-link>
            <h5 v-else>
              {{ props.row.name }}
            </h5>
          </span>
          <span v-else-if="props.column.field === 'action'">
            <span>
              <b-dropdown
                variant="link"
                toggle-class="text-decoration-none"
                no-caret
                text="Block Level Dropdown Menu"
                block
              >
                <template v-slot:button-content>
                  <feather-icon
                    icon="MoreVerticalIcon"
                    size="16"
                    class="text-body align-middle mr-25"
                  />
                </template>

                <b-dropdown-item
                  v-if="subPermission.update"
                  :to="{
                    name: 'EditSuppliers',
                    params: { id: props.row.id },
                  }"
                >
                  <feather-icon icon="EditIcon" class="mr-50" />
                  <span>{{ $t("g.edit") }}</span>
                </b-dropdown-item>
                <b-dropdown-item
                  v-if="subPermission.destroy"
                  @click="deleteAlert(props.row.id)"
                >
                  <feather-icon icon="Trash2Icon" class="mr-50" />
                  <span>{{ $t("g.delete") }}</span>
                </b-dropdown-item>
              </b-dropdown>
            </span>
          </span>

          <!-- Column: Common -->
          <span v-else>
            {{ props.formattedRow[props.column.field] }}
          </span>
        </template>

        <!-- pagination -->
        <template slot="pagination-bottom" slot-scope="props">
          <div class="d-flex justify-content-between flex-wrap">
            <div class="d-flex align-items-center mb-0 mt-1">
              <span class="text-nowrap">
                {{ $t("g.pagelength") }}
              </span>
              <b-form-select
                v-model="pageLength"
                :options="['5', '10', '20']"
                class="mx-1"
                @input="
                  (value) => props.perPageChanged({ currentPerPage: value })
                "
              />
              <span class="text-nowrap">
                {{ $t("g.of") }} {{ props.total }}
                {{ $t("g.pageText") }}
              </span>
            </div>
            <div>
              <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value) => props.pageChanged({ currentPage: value })"
              >
                <template #prev-text>
                  <feather-icon icon="ChevronLeftIcon" size="18" />
                </template>
                <template #next-text>
                  <feather-icon icon="ChevronRightIcon" size="18" />
                </template>
              </b-pagination>
            </div>
          </div>
        </template> </vue-good-table
    ></b-card>
  </div>
</template>

<script>
import {
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BCard,
  BDropdownItem,
  BDropdown,
  VBTooltip,
  BButton
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Loading from "@/views/components/logic/loading.vue";
import Error from "@/views/components/logic/error.vue";
import store from "@/store/index";
import Ripple from "vue-ripple-directive";
import ContentNotView from "@/views/components/logic/contentNotView.vue";

export default {
  components: {
    VueGoodTable,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BCard,
    Error,
    Loading,
    BDropdownItem,
    BDropdown,
    ContentNotView,
    BButton
  },
  directives: {
    "b-tooltip": VBTooltip,
    Ripple,
  },
  data() {
    return {
      log: [],
      pageLength: 20,
      dir: false,
      columns: [
        {
          label: "name",
          field: "name",
        },
        {
          label: "is_available",
          field: "is_available",
        },
        {
          label: "action",
          field: "action",
        },
      ],
      rows: [],
      searchTerm: "",
      isLoading: true,
      isError: false,
      subPermission: {},
    };
  },
  computed: {
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true;
        return this.dir;
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false;
      return this.dir;
    },
  },
  beforeMount() {
    this.getAllSuppliers();
  },
  methods: {
    getAllSuppliers() {
      this.$http
        .get("admin/suppliers")
        .then((response) => {
          if (response.status === 200) {
            
            this.rows = response.data.data.map((item) => ({
              name: item.name,
              is_available: item.is_available
                ? this.$t("g.Is Available")
                : this.$t("g.Not Available"),
              id: item.id,
            }));
            setTimeout(() => {
              this.pageStatus(false, false);
              store.dispatch("GET_PERMISSION", "suppliers");
              this.subPermission = store.state.permissions.sub;
            }, 1000);
          }
        })
        .catch((error) => {
          console.log(error);
          this.pageStatus(false, true);
        });
    },
    // Changes the state of the page according to the return of the data => ( success Or error )
    pageStatus(loading, error) {
      this.isLoading = loading;
      this.isError = error;
    },

    deleteItem(id) {
      this.rows = this.rows.filter((item) => item.id !== id);
    },

    deleteAlert(id) {
      this.$swal({
        title: this.$t("g.areYouSure?"),
        text: this.$t("g.youWontBeAbleToRevertThis"), // You won't be able to revert this!
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: this.$t("g.yesDeleteIt"), // "Yes, delete it!"
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
      }).then((result) => {
        if (result.value) {
          this.$http
            .delete(`admin/suppliers/${id}`)
            .then((res) => {
              if (res.status === 200) {
                this.deleteItem(id);
                this.$swal({
                  icon: "success",
                  title: this.$t("g.deleted"), //"Deleted!"
                  text: this.$t("g.yourFileHasBeenDeleted"), //"Your file has been deleted."
                  customClass: {
                    confirmButton: "btn btn-success",
                  },
                });
              }
            })
            .catch((err) => {
              console.log(err);
            });
        }
      });
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
</style>
